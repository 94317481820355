import i18n from '@/libs/i18n'
import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  min_value as rule_min_value,
  max_value as rule_max_value,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  double as rule_double,
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import el from 'vee-validate/dist/locale/el.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorPhone,
  validateIBAN,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const min_value = extend('min_value', rule_min_value)

export const max_value = extend('max_value', rule_max_value)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const double = extend('double', rule_double)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: i18n.t('Please enter positive number!'),
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: i18n.t('It is not valid credit card!'),
})

export const password = extend('password', {
  validate: validatorPassword,
  message: i18n.t(
    'Your {_field_} must be at least 8 characters long and must contain at least one uppercase, one lowercase, one special character and one digit',
  ),
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: i18n.t('URL is invalid'),
})

export const phone = extend('phone', {
  validate: validatorPhone,
  message: i18n.t('Phone is invalid'),
})

export const iban = extend('iban', {
  validate: validateIBAN,
  message: i18n.t('IBAN is invalid'),
})

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: i18n.t('{_field_} is too short, you want to get hacked?'),
      },
    },
  },
  el: {
    messages: el.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: i18n.t('{_field_} is too short, you want to get hacked?'),
      },
    },
  },
})

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
